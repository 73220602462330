import React from 'react'
import Layout from '../components/Layout'

const NotFoundPage = () => (
  <Layout>
    <section className='hero is-primary is-large'>
      <div className='hero-body'>
        <div className='container'>
          <div className='columns'>
            <div className='column is-10 is-offset-1'>
              <div className='section is-centered'>
                <h1 className='title'>
                  404: NICHT GEFUNDEN
                </h1>
                <h2 className=' anthracite-text'>
                  Leider haben Sie eine Seite aufgerufen, die nicht existiert. Sollten Sie einem Link gefolgt sein, lassen Sie uns das bitte über das <a href='/contact'>Kontaktformular</a> wissen.
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div className='yellow-border' />
  </Layout>
)

export default NotFoundPage
